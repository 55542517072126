import React from "react";
import { graphql } from "gatsby";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/core/Seo";
import SectionHomeStudio from "../components/PageBlocks/SectionHomeStudio";
import SectionMyVoice from "../components/PageBlocks/SectionMyVoice";
import BaseLayout from "../components/core/BaseLayout";
import styled from "styled-components";
import {
  Button,
  WaveFooter,
  Section,
  Content,
  FeatureDuo,
  Headline,
  Subline,
  Text,
  MobileVisualization,
  DesktopVisualization,
  TextLinkExternal,
  WaveDividerThree,
} from "../components/SubComponents";

import yearsSince from "../util/yearsSince";
// import { ImageContainer, ImageMask } from "../components/Atoms";
import { ImageContainer } from "../components/Atoms";
import { SITEMAP_LINKS } from "../constants/linkdata";

const SubSection = styled.section``;

const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const GetInTouch = styled(Button)`
  font-size: 1.3em;
  margin-top: 1em;
  padding: 0.7em 2.2em;
`;

const Age = () => {
  const birthdate = "1994/8/12";
  return <>{yearsSince(birthdate)}</>;
};

const MovedDate = () => {
  const movedDate = "2013/3/1";
  return <>{yearsSince(movedDate)}</>;
};

const AboutPage = ({ location: { pathname } }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  return (
    <BaseLayout>
      <SEO title={t("About Me")} path={pathname} />
      <Section id={"about"}>
        <Content noSpaceBottom>
          <Headline hero>
            <Trans>Hi, I’m Sarah – I bring your words to life.</Trans>
          </Headline>
          <FeatureDuo columnRatio={[6, 5]}>
            <Text>
              <MobileVisualization>
                <ImageContainer>
                  <StaticImage
                    src="../assets/images/jpg/greenheadphones.jpg"
                    alt="Sarah Kolb"
                    placeholder="tracedSVG"
                    style={{ borderRadius: "1.3em" }}
                  />
                </ImageContainer>
              </MobileVisualization>
              <SubSection>
                <Subline>
                  {/* prettier-ignore */}
                  {language === "en" ? (
                    <>
                      I’m a professional <b>voiceover artist</b>,{" "}
                      <b>language director</b> and <b>script supervisor</b>{" "}
                      originally from a small village near <b>Frankfurt</b>,
                      Germany.
                    </>
                  ) : (
                    <>
                      Ich heiße Sarah, bin <Age /> Jahre alt und komme
                      ursprünglich aus dem klitzekleinen Bruchköbel-Roßdorf bei
                      Frankfurt am Main.
                    </>
                  )}
                </Subline>
                <Subline>
                  {/* prettier-ignore */}
                  {language === "en" ? (
                    <>
                      After <b>a bilingual German/English</b> upbringing and an
                      early childhood stint in <b>Singapore</b>, I moved to{" "}
                      <b>London</b> in 2013 to study Musical Theatre. Since
                      then, I’ve built a diverse creative career in voice
                      acting, narration, acting, singing, and teaching – more on
                      that{" "}
                      <TextLinkExternal href={SITEMAP_LINKS.SPOTLIGHT.URL}>
                        here
                      </TextLinkExternal>
                      !
                    </>
                  ) : (
                    <>
                      Vor fast <MovedDate /> Jahren zog ich nach London, um hier
                      Musical und Bühnentanz am London Studio Centre zu
                      studieren. Seitdem bin ich als Schauspielerin, Sängerin
                      und Sprecherin tätig.
                    </>
                  )}
                </Subline>
                <Subline>
                  {/* prettier-ignore */}
                  <Trans>
                  Languages have always been my thing. Growing up bilingual, I’ve always been captivated by learning new languages. This passion gives me a unique ability to really understand and connect with scripts, ensuring every line feels authentic and true to life. <b>My goal? To make your words sound and feel real and human, every single time.</b>
                  </Trans>
                </Subline>
                <Subline>
                  {/* prettier-ignore */}
                  <Trans>
                  I’m a sucker for a challenge. I’m trained in the Sanford Meisner acting technique, a certified yoga and Barre Fit instructor, and I hold a BADC Stage Combat qualification. In my downtime, you might find me knitting (a pandemic hobby that stuck) or immersed in the world of Tango Argentino (yes, I’m obsessed).
                  </Trans>
                </Subline>
                <Subline>
                  {/* prettier-ignore */}
                  {language === "en" ? (
                    <b>
                      So, if you’re looking for someone who’s versatile,
                      skilled, and passionate about their craft, let’s create
                      something amazing together.
                    </b>
                  ) : (
                    <>
                      Falls Sie neugierig sind, finden Sie mein Künstlerprofil
                      inklusive vollständigem Lebenslauf{" "}
                      <TextLinkExternal href={SITEMAP_LINKS.SPOTLIGHT.URL}>
                        hier
                      </TextLinkExternal>
                      !
                    </>
                  )}
                </Subline>
              </SubSection>
              <ButtonWrapper>
                <GetInTouch ghost={false} to="/contact" variant="primary">
                  <Trans>Get in touch!</Trans>
                </GetInTouch>
              </ButtonWrapper>
            </Text>
            <DesktopVisualization>
              <ImageContainer>
                <StaticImage
                  src="../assets/images/jpg/greenheadphones.jpg"
                  alt="Sarah Kolb"
                  placeholder="tracedSVG"
                  tracedSVGOptions={{
                    color: "rgb(240, 240, 240)",
                  }}
                  style={{ borderRadius: "1.8em" }}
                />
              </ImageContainer>
            </DesktopVisualization>
          </FeatureDuo>
        </Content>
        <WaveDividerThree bgColor="lighter" />
      </Section>
      <SectionMyVoice />
      <SectionHomeStudio />
      <WaveFooter style={{ width: "100%" }} />
    </BaseLayout>
  );
};

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "about"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
