import React from "react";
import { Trans } from "gatsby-plugin-react-i18next";
import styled from "styled-components";
import {
  WaveDividerOne,
  Content,
  Section,
  FeatureDuo,
  Text,
  Headline,
  Subline,
  Visualization,
} from "../SubComponents";

const lightColor = ({ theme }) => theme.backgroundColors.lighterColor;

const VoiceContent = styled(Content)`
  ${({ theme }) => theme.media.tabletLandscape`
    max-width: 70%;
  `};
  ${({ theme }) => theme.media.desktop`
    max-width: 54%;
  `};
  ${({ theme }) => theme.media.desktopPlus`
    max-width: 45%;
  `};
`;

const SectionMyVoice = () => {
  return (
    <Section id={"voice"} gapWidth={6} bgColor={lightColor}>
      <VoiceContent>
        <FeatureDuo verticalOnly spaceBottom noGap>
          <Text bright>
            <Headline>
              <Trans>My Voice</Trans>
            </Headline>
          </Text>
          <Visualization>
            <Subline bright>
              <Trans>
                Bright, engaging, relatable. Youthful and contemporary, yet
                trustworthy and assured. With a heart-warming smile or a
                sprinkling of attitude. As crisp and energetic or urban and
                laid-back as you’d like.
              </Trans>
            </Subline>
            <Subline bright>
              <Trans>Playing age? Teens - 30s.</Trans>
            </Subline>
          </Visualization>
        </FeatureDuo>
      </VoiceContent>
      <WaveDividerOne bgColor="bright" />
    </Section>
  );
};

export default SectionMyVoice;
